import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import App from './App';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import router from './router';

// eslint disable-next-line import/no-extraneous-dependencies
// import 'swiper/dist/css/swiper.css';

const VueScrollTo = require('vue-scrollto');

Vue.config.productionTip = false;
Vue.use(VueScrollTo);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAP_API_KEY,
    libraries: ['places'],
  },
});

const mailerLocalhost = 'http://localhost:8080/';
const mailerProdhost = 'https://djavu.cl/mailing/';
const MAILER_HOST = process.env.VUE_APP_MAILER_HOST || (
  process.env.NODE_ENV === 'production'
    ? mailerProdhost : mailerLocalhost);

Vue.prototype.$MAILER_HOST = MAILER_HOST;

new Vue({
  vuetify,
  i18n,
  router,
  render: h => h(App),
}).$mount('#app');
