<template>
  <div class="contact__container w-100">
    <div
      class="animatedcards w-100"
      :class="{
        'd-inline-flex align-center justify-space-between': $vuetify.breakpoint.mdAndUp,
        'animatedcards--show': show_cards,
        'animatedcards--hide': !show_cards
      }"
      ref="contact_cards"
    >
      <div class="text-left white--text">
        <p class="display-1 text-left font-weight-bold">{{ $t('lets_talk.title') }}</p>
        <p class="mt-3 text-left" style="max-width: 800px">
          {{ $t('lets_talk.content') }}
        </p>
      </div>
      <div :class="{ 'mt-10': !$vuetify.breakpoint.mdAndUp }">
        <div
          class="d-inline-flex justify-center align-center contact__btn py-3 px-12"
          @click="contactModal = true"
        >
          {{ $t('lets_talk.button') }}
        </div>
      </div>
    </div>
    <v-dialog max-width="100vw" width="750" v-model="contactModal">
      <contact-form @close="contactModal = false" />
    </v-dialog>
  </div>
</template>
<script>
import ContactForm from './ContactForm';

export default {
  components: { ContactForm },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      request: '',
      budget: '',
      deadline: '',

      details: false,

      sending_email: false,
      sent: false,

      show_cards: false,
      contactModal: false,
    };
  },
  computed: {
    emailIsInvalid() {
      // eslint-disable-next-line no-useless-escape
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return !reg.test(this.email);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.listener);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.listener);
  },
  methods: {
    listener() {
      const vm = this;
      this.$nextTick().then(() => {
        const cardsdistance = vm.$refs.contact_cards.getBoundingClientRect().top - window.innerHeight;
        const cardsVisible = cardsdistance < 20;
        if (cardsVisible !== vm.show_cards) {
          vm.show_cards = cardsVisible;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.contact__container {
  padding: 60px 64px;
  background-color: $djablue;
}
.contact__text-container {
  max-width: 700px;
}
.contact__text {
  color: white;
}
.contact__title {
  font-size: 2.5rem;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 2rem;
  }
  font-weight: 900;
}
.contact__subtitle {
  font-size: 1rem;
}
.contact__btn {
  cursor: pointer;
  color: white;
  border: 1px solid white;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 2em;
  border-radius: 3rem;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  transition: all .2s ease;

  &:hover{
    background-color: white;
    color: $djablue;
  }
}
</style>
