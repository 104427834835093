<template>
  <div class="form-container">
    <v-row no-gutters justify="center">
      <v-col md="12" align="center" class="d-flex justify-center">
        <v-img contain height="88" :src="require('@/assets/img/1x_PNG/logo_principal_positivo.png')"/>
      </v-col>
      <div class="contact-grid text-start mt-3">
        <v-icon color="primary" small>mdi-email</v-icon><div class=" body-2">contacto@djavu.cl</div>
        <v-icon color="primary" small>mdi-phone</v-icon><div  class=" body-2">+56 9 9965 1419</div>
      </div>
    </v-row>
    <v-form ref="form" v-model="form">
      <v-row no-gutters justify="center" class="mt-12">
        <v-text-field
          ref="name"
          dense
          outlined
          v-model="name"
          maxlength="30"
          :rules="[() => !!name || $t('contact_form.name_error'), rules.length(30)]"
          :label="$t('contact_form.name_label')"
          required/>
      </v-row>
      <v-row no-gutters justify="center">
        <v-col cols="12" md="6" no-gutters class="pr-md-1">
          <v-text-field
          ref="email"
          outlined
          dense
          v-model="email"
          :label="$t('contact_form.email_label')"
          maxlength="320"
          :rules="[() => !!email || $t('contact_form.email_error'), rules.length(320)]"
          :error-messages="(email && emailIsInvalid)? $t('contact_form.email_invalid') : ''"/>
        </v-col>
        <v-col cols="12" md="6" no-gutters class="pl-md-1">
          <v-text-field
          ref="phone"
          class="mb-0"
          outlined
          dense
          maxlength="9"
          :rules="[rules.length(9), rules.phone]"
          v-model="phone"
          :label="$t('contact_form.phone_label')"
          />
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <v-col cols="12" md="6" no-gutters class="pr-md-1">
          <v-text-field
          ref="budget"
          class="mb-6"
          counter
          dense
          maxlength="25"
          :rules="[rules.length(25)]"
          outlined
          hide-details
          v-model="budget"
          :label="$t('contact_form.budget_label')"
          />
        </v-col>
        <v-col cols="12" md="6" no-gutters class="pl-md-1">
          <v-text-field
          ref="deadline"
          class="mb-6"
          dense
          outlined
          maxlength="25"
          :rules="[rules.length(25)]"
          hide-details
          v-model="deadline"
          :label="$t('contact_form.deadline_label')"
          />
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <v-textarea
          ref="request"
          class="mb-4"
          counter
          maxlength="500"
          :rules="[rules.length(500)]"
          outlined
          v-model="request"
          :label="$t('contact_form.about_label')"
        />
      </v-row>
    </v-form>
    <v-row no-gutters justify="center" class="mt-4">
      <v-col cols="6" class="px-2">
        <v-btn
          height="48"
          depressed
          block
          @click="$emit('close')"
        >
          <!-- @click="$refs.form.reset()" -->
          {{ $t('buttons.back') }}
        </v-btn>
      </v-col>
      <v-col cols="6" class="px-2">
        <v-btn
          height="48"
          depressed
          block
          color="#62f4a4"
          @click="send"
          :loading="sending_email"
          :disabled="!form"
        >
          <p class="btn__text">{{ $t('buttons.send') }}</p>
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      absolute
      bottom
      text
      v-model="snackbar.show"
      :color="snackbar.color"
    >
      <div class="text--accent-3" :class="`${snackbar.color}--text`" v-html="snackbar.msg"></div>

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          :class="`${snackbar.color}--text`"
          v-bind="attrs"
          @click="$emit('close'); snackbar.show = false"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ContactForm',
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      request: '',
      form: false,
      budget: '',
      deadline: '',
      rules: {
        phone: v => !!(v || '').match(/^[0-9]*$/) || this.$t('contact_form.phone_invalid'),
        length: len => v => (v || '').length <= len || `Máximo ${len} caracteres`,
      },
      snackbar: {
        msg: '',
        color: null,
        show: false,
      },
      sending_email: false,
      sent: false,
    };
  },
  computed: {
    emailIsInvalid() {
      // eslint-disable-next-line no-useless-escape
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return !reg.test(this.email);
    },
  },
  methods: {
    send() {
      const data = {
        email: this.email,
        name: this.name,
        phone: this.phone ? this.phone : undefined,
        company: this.company ? this.company : undefined,
        position: this.position ? this.position : undefined,
        request: this.request ? this.request : undefined,
        budget: this.budget ? this.budget : undefined,
        deadline: this.deadline ? this.deadline : undefined,
      };

      this.sending_email = true;

      axios.post(this.$MAILER_HOST, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then(() => {
          this.snackbar.color = 'success';
          this.snackbar.msg = '¡Mensaje enviado!';
          this.sent = true;
        })
        .catch(() => {
          this.snackbar.color = 'error';
          this.snackbar.msg = '¡Oops! Algo falló. Puedes escribirnos a <strong>contacto@djavu.cl</strong>';
        })
        .finally(() => {
          this.snackbar.show = true;
          this.sending_email = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.form-container {
  background-color: white;
  padding: 52px 20px 32px 20px;

  & .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: $verdjavu !important;
    opacity: 0.5;
  }
}
.btn__text {
  font-size: 1rem;
  font-weight: 600;
  line-height: 2em;
  color: white;
}
.contact-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto 1fr;
  grid-gap: 0px 20px;

}
</style>
