import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import {
  djablue, verdjavu, grayDark,
} from '@/sass/variables.scss';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  breakpoint: {
    mobileBreakpoint: 'sm',
  },
  theme: {
    options: {
      customProperties: true, // see https://v2.vuetifyjs.com/en/features/theme/#custom-properties
    },
    themes: {
      light: {
        primary: grayDark,
        secondary: djablue,
        accent: verdjavu,
        // error: '#D32F2F', //'#050302',
        // info: '#FFFEFF',
        // success: '#7a904a',
        // warning: '#f78012',
        // anchor: verdjavu,
      },
    },
  },
});
