/** External routes available at djavu.cl/some-route */

/** Converts simple object with key-values to vue router entries */
const objToRoutes = (obj) => {
  const capitalize = ([cap, ...rest]) => cap.toUpperCase() + rest.join('').toLowerCase();
  return Object.keys(obj)
    .map(key => ({
      path: `/${key}`,
      name: `/${capitalize(key)}`,
      beforeEnter() {
        window.location.replace(obj[key]);
      },
    }));
};

export default objToRoutes({
  beneficios: 'https://www.djavuadmin.biss.cl/2022/04/05/mis-beneficios-djavu/',
  gantt: 'https://macguionbajo.gitlab.io/djavugantt',
  gather: 'https://gather.town/app/5Kpwydb5eYSJxUpn/djavu',
  firma: 'https://macguionbajo.gitlab.io/djavu-email-signature/',
  blog: 'http://www.djavuadmin.biss.cl/',
});
