<template>
  <v-app>
    <v-main class="py-0" style="background-color: #EEEEEE">
      <router-view />
      <!--<location />-->
      <contact id="contact" />
      <v-footer padless>
        <v-row no-gutters class="footer__container pt-2">
          <p class="footer__text">{{ new Date().getFullYear() }} — Djavu</p>
        </v-row>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import Contact from './components/Contact';
// import Location from './components/Location';

export default {
  name: 'App',
  components: {
    Contact,
    // Location,
  },
  mounted() {
    this.$nextTick(() => {
    // The whole view is rendered, so I can safely access or query
    // the DOM. ¯\_(ツ)_/¯
      this.$vuetify.theme.themes.light.primary = '#0d1064';
      this.$vuetify.goTo(0);
    });
  },
  beforeMount() {
    if (window.location.hostname === 'djavu.cl') {
      if (window.location.protocol !== 'https:') {
        window.location.href = `https:${window.location.href.substring(window.location.protocol.length)}`;
      }
    }
  },
};
</script>
<style lang="scss">
// Global styles.

// Colors
.bg {
  &-primary {
    background-color: $primary;
    &-variant {
      background-color: $primary-variant;
    }
  }
  &-secondary {
    background-color: $secondary;
    &-variant {
      background-color: $secondary-variant;
    }
  }
  &-accent {
    background-color: $accent;
    &-variant {
      background-color: $accent-variant;
    }
  }
}

h1 {
  font-size: 2.5em;
  font-weight: bolder;
}
// TODO: Define custom sizes, weights and families for h2 h3 h4 h5 and h6

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100%;
}
p {
  margin-bottom: 0 !important;
  text-align: center !important;
}
#team {
  width: 100% !important;
}
.animatedcards {
  transition: transform 0.8s ease-in-out, opacity 0.6s ease-in-out;
  &--show {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
  &--hide {
    transform: translateY(20%) scale(0.9);
    opacity: 0;
  }
}
.animatedtext {
  position: absolute;
  transition: opacity 0.6s ease-in-out;
  &--show {
    opacity: 1;
  }
  &--hide {
    opacity: 0;
  }
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: end;
}
.slick-slide {
  height: 120px !important;
}
.slick-dots {
  bottom: -70px;
  z-index: 10;
}
.footer__container {
  padding-left: 64px;
  padding-bottom: 20px;
  background-color: $djablue;
}
.footer__text {
  font-weight: 500;
  color: white;
  font-size: 1rem;
}
</style>
