import Vue from 'vue';
import VueRouter from 'vue-router';
import externalRoutes from '@/assets/data/externalRoutes';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('@/views/Projects'),
  },
  {
    path: '/projects2',
    name: 'Projects2',
    component: () => import('@/views/Projects2'),
  },
  ...externalRoutes,
];

const PUBLIC_PATH = process.env.VUE_APP_PUBLIC_PATH || '/';

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production'
    ? PUBLIC_PATH : (process.env.BASE_URL || '/'),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  if (window.location.hostname.match(/^(www|site)\.djavu\.cl$/g)) {
    window.location = window.location.href.replace(/^https?:\/\/(www|site)\./, 'https://');
    return false;
  }
  return next();
});
export default router;
